<template>
  <div>
    <section class="section section-secondary jpadding">
      <div class="jcard jcard-main jcard-nohover">
        <!-- title -->
        <div class="mb-4 text-center">
          <h3 class="mb-1">APPOINTMENT INFO</h3>
          <p>
            <small><span class="badge badge-pill badge-light">{{ appointmentId }}</span></small>
          </p>
        </div>

        <!-- service -->
        <div class="jcard-mini">
          <p>
            <small><i class="fal fa-cog"></i> SERVICE</small><br />{{ service.name }}
          </p>
        </div>

        <!-- details -->
        <div class="grid-4 mt-3">
          <div class="jcard-mini">
            <p>
              <small><i class="fal fa-calendar"></i> DATE</small><br />{{
                $moment($C.getDateFromTimestamp(appointment.date)).format("MM/YYYY")
              }}
            </p>
          </div>
          <div class="jcard-mini">
            <p>
              <small><i class="fal fa-user"></i> CUSTOMER</small><br />{{
                user.firstName + " " + user.lastName
              }}
            </p>
          </div>
          <div class="jcard-mini">
            <p>
              <small><i class="fal fa-watch"></i> WATCH MODEL</small><br />{{ model.name }}
            </p>
          </div>
        </div>
      </div>

      <!-- card 2 -->
      <div class="jcard jcard-main jcard-nohover mt-3">
        <!-- actions/steps -->
        <div class="card-title">
          <h3>
            <small><i class="fal fa-cog"></i></small> Manage
          </h3>
          <p>
            <small><span
                :class="{ 'font-weight-bold': appointment.status == $C.STATUS.APPOINTMENT_STATUS.UNCONFIRMED }">Step 1:
                Confirm</span>
              /
              <span :class="{
                  'font-weight-bold':
                    appointment.status == $C.STATUS.APPOINTMENT_STATUS.CONFIRMED ||
                    appointment.status == $C.STATUS.APPOINTMENT_STATUS.WATCH_RECEIVED
                }">Step 2: Receive</span>
              /
              <span :class="{
                  'font-weight-bold':
                    appointment.status == $C.STATUS.APPOINTMENT_STATUS.IN_PROGRESS ||
                    appointment.status == $C.STATUS.APPOINTMENT_STATUS.WORK_COMPLETED
                }">Step 3: Service</span>
              /
              <span :class="{ 'font-weight-bold': appointment.status == $C.STATUS.APPOINTMENT_STATUS.IN_DELIVERY }">Step
                4: Delivery</span>
              /
              <span :class="{ 'font-weight-bold': appointment.status == $C.STATUS.APPOINTMENT_STATUS.COMPLETED }">Step 5:
                Complete</span></small>
          </p>
        </div>

        <!-- ----- step 1: unconfirmed --------------------------------- -->
        <div v-if="appointment.status == $C.STATUS.APPOINTMENT_STATUS.UNCONFIRMED" key="step1" id="step1"
          class="appointment-step">
          <div class="jcard-mini jalert">
            <p><i class="fal fa-info-circle fa-2x"></i></p>
            <p>
              <small></small>
              A customer has requested a new service appointment for a watch. After verifying the date, please
              accept or decline the request.
            </p>
          </div>
          <div class="appointment-step-content text-center">
            <h3><i class="fal fa-calendar-check fa-2x"></i></h3>
            <p>
              Requested Date:<br />
              <b>{{ $moment($C.getDateFromTimestamp(appointment.date)).format("MMM YYYY") }}</b>
            </p>

            <div class="jcard-btn-container-center mt-4">
              <button @click="updateAppointmentStatus($C.STATUS.APPOINTMENT_STATUS.DECLINED)"
                class="jbtn jbtn-sm jbtn-red mr-3">
                <i class="fal fa-times"></i> Decline
              </button>
              <button @click="updateAppointmentStatus($C.STATUS.APPOINTMENT_STATUS.CONFIRMED)" class="jbtn jbtn-sm">
                <i class="fal fa-check"></i> Confirm
              </button>
            </div>
          </div>
        </div>

        <!-- ----- step 2: confirmed --------------------------------- -->
        <div v-if="appointment.status == $C.STATUS.APPOINTMENT_STATUS.CONFIRMED" key="step2" id="step2"
          class="appointment-step">
          <div class="jcard-mini jalert">
            <p><i class="fal fa-info-circle fa-2x"></i></p>
            <p>
              <small></small>
              This appointment has been confirmed. The customer needs to send the watch to DUKE in order to
              start the requested service. It is recommended that the customer provides delivery information.
            </p>
          </div>
          <div class="appointment-step-content text-center">
            <h3><i class="fal fa-check fa-2x"></i></h3>
            <h5>Request Confirmed</h5>
            <p>
              Waiting for customers<br />
              watch to arrive.
            </p>
          </div>
        </div>

        <!-- ----- step -1: declined --------------------------------- -->
        <div v-if="appointment.status == $C.STATUS.APPOINTMENT_STATUS.DECLINED" key="step9" id="step9"
          class="appointment-step">
          <div class="jcard-mini jalert">
            <p><i class="fal fa-info-circle fa-2x"></i></p>
            <p>
              This appointment has been declined.
            </p>
          </div>
          <div class="appointment-step-content text-center">
            <h3><i class="fal fa-times-circle fa-2x"></i></h3>
            <h5>Request Declined</h5>
            <div class="jcard-mini">
              <p>
                <b>Reason:</b><br>
                {{ (editData.declineReason) ? editData.declineReason : 'No reason specified.' }}
              </p>
            </div>

            <!-- btn container -->
            <div class="btn-container text-center mt-3 mb-3">
              <button @click="openDeclineDialog" class="jbtn jbtn-sm">
                <i class="fal fa-pencil"></i> Edit reason
              </button>
            </div>
          </div>
        </div>

        <!-- ----- step 3: in progress --------------------------------- -->
        <div v-if="appointment.status == $C.STATUS.APPOINTMENT_STATUS.WATCH_RECEIVED" key="step3" id="step3"
          class="appointment-step">
          <div class="jcard-mini jalert">
            <p><i class="fal fa-info-circle fa-2x"></i></p>
            <p>
              <small></small>
              This step is intended to start once DUKE has received the watch. Please enter an estimated
              service completion date that will be notified to the customer (Optional) You can always adjust
              the date later.
            </p>
          </div>
          <div class="appointment-step-content text-center">
            <h3><i class="fal fa-box-alt fa-2x"></i></h3>
            <h5>Watch has arrived</h5>
            <p>
              Please fill out the<br />
              info below to continue:
            </p>
          </div>

          <div class="mt-5">
            <div class="form-row">
              <div class="col-md">
                <div class="form-group">
                  <label for="estimatedFinishDate"><i class="fal fa-calendar-alt"></i> Estimated finish date</label>
                  <input type="date" v-bind:class="{
                      'form-control': true,
                      'is-invalid': !validDate(editData.estimatedFinishDate) && bluredEstimatedFinishDate
                    }" v-on:blur="bluredEstimatedFinishDate = true" v-model="editData.estimatedFinishDate" />
                  <div class="invalid-feedback">
                    Please enter an estimated date.
                  </div>
                </div>
              </div>
            </div>

            <!-- alert -->
            <b-alert v-if="showInputError" show variant="danger"><i class="fad fa-exclamation-circle"></i>
              {{ validationMsg }}
            </b-alert>
          </div>
        </div>

        <!-- ----- step 4: in progress --------------------------------- -->
        <div v-if="appointment.status == $C.STATUS.APPOINTMENT_STATUS.IN_PROGRESS" key="step4" id="step4"
          class="appointment-step">
          <div class="jcard-mini jalert">
            <p><i class="fal fa-info-circle fa-2x"></i></p>
            <p>
              <small></small>
              This service is currently being worked on. Here you can edit the estimated completion date.
              Continue to next step after the service has been executed.
            </p>
          </div>
          <div class="appointment-step-content text-center">
            <h3><i class="fal fa-cog fa-2x"></i></h3>
            <h5>Work in progress</h5>
            <p>
              Continue once the<br />
              service is completed.
            </p>
          </div>

          <div class="mt-5">
            <div class="form-row">
              <div class="col-md">
                <div class="form-group">
                  <label for="purchasedOn"><i class="fal fa-calendar-alt"></i> Estimated finish date</label>
                  <input type="date" v-bind:class="{
                      'form-control': true,
                      'is-invalid': !validDate(editData.estimatedFinishDate) && bluredEstimatedFinishDate
                    }" v-on:blur="bluredEstimatedFinishDate = true" v-model="editData.estimatedFinishDate" />
                  <div class="invalid-feedback">
                    Please enter an estimated date.
                  </div>
                </div>
              </div>
            </div>

            <!-- alert -->
            <b-alert v-if="showInputError" show variant="danger"><i class="fad fa-exclamation-circle"></i>
              {{ validationMsg }}
            </b-alert>

            <!-- btn container -->
            <div class="btn-container text-center mt-3 mb-3">
              <button @click="updateStatusInProgress" class="jbtn jbtn-sm">
                <i class="fal fa-save"></i> Update
              </button>
            </div>
          </div>
        </div>

        <!-- ----- step 5: delivery --------------------------------- -->
        <div v-if="appointment.status == $C.STATUS.APPOINTMENT_STATUS.WORK_COMPLETED" key="step5" id="step5"
          class="appointment-step">
          <div class="jcard-mini jalert">
            <p><i class="fal fa-info-circle fa-2x"></i></p>
            <p>
              <small></small>
              This service is currently being worked on. Here you can edit the estimated completion date.
              Continue to next step after the service has been executed.
            </p>
          </div>
          <div class="appointment-step-content text-center">
            <h3><i class="fal fa-tools fa-2x"></i></h3>
            <h5>Work Completed</h5>
            <p>
              Please fill out the<br />
              info below to continue:
            </p>
          </div>

          <div class="mt-5">
            <div class="form-row">
              <div class="col-md form-group">
                <label for="dispatchDate"><i class="fal fa-calendar-alt"></i> Dispatch date</label>
                <input type="date" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validDate(editData.dispatchDate) && bluredDispatchDate
                  }" v-on:blur="bluredDispatchDate = true" v-model="editData.dispatchDate" />
                <div class="invalid-feedback">
                  Please enter a valid date.
                </div>
              </div>
              <div class="col-md form-group">
                <label for="deliveryDate"><i class="fal fa-calendar-alt"></i> Estimated delivery date</label>
                <input type="date" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validDate(editData.deliveryDate) && bluredDeliveryDate
                  }" v-on:blur="bluredDeliveryDate = true" v-model="editData.deliveryDate" />
                <div class="invalid-feedback">
                  Please enter a valid date.
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md form-group">
                <label for="deliveryTrackingId"><i class="fal fa-truck"></i> Carrier</label>
                <input type="text" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validInput(editData.deliveryCarrier) && bluredDeliveryCarrier
                  }" v-on:blur="bluredDeliveryCarrier = true" v-model="editData.deliveryCarrier" />
                <div class="invalid-feedback">
                  Please enter valid carrier name.
                </div>
              </div>
              <div class="col-md form-group">
                <label for="deliveryTrackingId"><i class="fal fa-box-alt"></i> Tracking Id</label>
                <input type="text" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validInput(editData.deliveryTrackingId) && bluredTrackingId
                  }" v-on:blur="bluredTrackingId = true" v-model="editData.deliveryTrackingId" />
                <div class="invalid-feedback">
                  Please enter valid tracking info.
                </div>
              </div>
            </div>
            <!-- alert -->
            <b-alert v-if="showInputError" show variant="danger"><i class="fad fa-exclamation-circle"></i>
              {{ validationMsg }}
            </b-alert>
          </div>
        </div>

        <!-- ----- step 6: in delivery --------------------------------- -->
        <div v-if="appointment.status == $C.STATUS.APPOINTMENT_STATUS.IN_DELIVERY" key="step6" id="step6"
          class="appointment-step">
          <div class="jcard-mini jalert">
            <p><i class="fal fa-info-circle fa-2x"></i></p>
            <p>
              <small></small>
              Almost done. You can edit delivery information and continue once the delivery has arrived at
              it's destination.
            </p>
          </div>
          <div class="appointment-step-content text-center">
            <h3><i class="fal fa-truck fa-2x"></i></h3>
            <h5>In Delivery</h5>
            <p>
              Please update after<br />
              the delivery has arrived.
            </p>
          </div>

          <div class="mt-5">
            <div class="form-row">
              <div class="col-md form-group">
                <label for="dispatchDate"><i class="fal fa-calendar-alt"></i> Dispatch date</label>
                <input type="date" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validDate(editData.dispatchDate) && bluredDispatchDate
                  }" v-on:blur="bluredDispatchDate = true" v-model="editData.dispatchDate" />
                <div class="invalid-feedback">
                  Please enter a valid date.
                </div>
              </div>
              <div class="col-md form-group">
                <label for="deliveryDate"><i class="fal fa-calendar-alt"></i> Estimated delivery date</label>
                <input type="date" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validDate(editData.deliveryDate) && bluredDeliveryDate
                  }" v-on:blur="bluredDeliveryDate = true" v-model="editData.deliveryDate" />
                <div class="invalid-feedback">
                  Please enter a valid date.
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md form-group">
                <label for="deliveryTrackingId"><i class="fal fa-truck"></i> Carrier</label>
                <input type="text" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validInput(editData.deliveryCarrier) && bluredDeliveryCarrier
                  }" v-on:blur="bluredDeliveryCarrier = true" v-model="editData.deliveryCarrier" />
                <div class="invalid-feedback">
                  Please enter valid carrier name.
                </div>
              </div>
              <div class="col-md form-group">
                <label for="deliveryTrackingId"><i class="fal fa-box-alt"></i> Tracking Id</label>
                <input type="text" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validInput(editData.deliveryTrackingId) && bluredTrackingId
                  }" v-on:blur="bluredTrackingId = true" v-model="editData.deliveryTrackingId" />
                <div class="invalid-feedback">
                  Please enter valid tracking info.
                </div>
              </div>
            </div>

            <!-- alert -->
            <b-alert v-if="showInputError" show variant="danger"><i class="fad fa-exclamation-circle"></i>
              {{ validationMsg }}
            </b-alert>
          </div>

          <!-- btn container -->
          <div class="btn-container text-center mt-3 mb-3">
            <button @click="updateStatusInDelivery" class="jbtn jbtn-sm">
              <i class="fal fa-save"></i> Update
            </button>
          </div>
        </div>

        <!-- ----- step 7: completed --------------------------------- -->
        <div v-if="appointment.status == $C.STATUS.APPOINTMENT_STATUS.COMPLETED" key="step7" id="step7"
          class="appointment-step">
          <div class="jcard-mini jalert">
            <p><i class="fal fa-info-circle fa-2x"></i></p>
            <p>
              <small></small>
              This appointment has been completed. The service has been archived in the watch's Service
              History.
            </p>
          </div>
          <div class="appointment-step-content text-center">
            <h3><i class="fal fa-check-circle fa-2x"></i></h3>
            <h5>Request Completed</h5>
            <p>
              All done!
            </p>
          </div>
        </div>

        <!-- ----- step -2: cancelled --------------------------------- -->
        <div v-if="appointment.status == $C.STATUS.APPOINTMENT_STATUS.CANCELLED" key="step8" id="step8"
          class="appointment-step">
          <div class="jcard-mini jalert">
            <p><i class="fal fa-info-circle fa-2x"></i></p>
            <p>
              This appointment has been cancelled.
            </p>
          </div>
          <div class="appointment-step-content text-center">
            <h3><i class="fal fa-times-circle fa-2x"></i></h3>
            <h5>Request Cancelled</h5>
            <p></p>
          </div>
        </div>

        <!-- steps bottom btns -->
        <div v-if="appointment.status != $C.STATUS.APPOINTMENT_STATUS.UNCONFIRMED"
          class="appointment-step-bottom d-flex mt-3">
          <button @click="previous" class="jbtn-text"><i class="fal fa-chevron-left"></i> Previous</button>
          <button @click="next"
            v-if="appointment.status != $C.STATUS.APPOINTMENT_STATUS.COMPLETED && appointment.status != $C.STATUS.APPOINTMENT_STATUS.DECLINED"
            class="jbtn jbtn-sm ml-auto">
            Next <i class="fal fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </section>

    <!-- ----- decline appointment modal --------------------------------- -->
    <b-modal :hide-header="true" :hide-footer="true" id="decline-modal" ref="decline-modal" centered title="BootstrapVue">
      <div class="jdialog-top">
        <div class="close-container">
          <h3>
            <strong>Decline</strong>
          </h3>
          <span @click="$bvModal.hide('decline-modal')" class="ml-auto jclose"><i class="fa fa-times "></i></span>
        </div>
        <p>
          <small>Please provide a reason for the decline.</small>
        </p>
      </div>
      <div class="jdialog-main">
        <div class="form-group">
          <label for="text"><i class="fal fa-comment-alt"></i> Decline Reason (Optional)</label>
          <textarea type="text" class="form-control" v-model="editData.declineReason" />
        </div>

        <!-- alert -->
        <b-alert v-if="showInputError" show variant="danger"><i class="fad fa-exclamation-circle"></i>
          {{ validationMsg }}
        </b-alert>
      </div>

      <!-- dialog bottom -->
      <div class="jdialog-bottom with-cancel">
        <button @click="updateStatusDeclined" class="jbtn jbtn-sm jbtn-red">
          <i class="fa fa-times"></i> Decline
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import C from "@/const";
import toast from "@/assets/js/toast";
import moment from "moment";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;
const serverTimestamp = firebase.serverTimestamp;

export default {
  name: "AppointmentCardAdmin",
  data() {
    return {
      //display
      showError: false,
      isLoading: false,
      isSaving: false,
      editMode: false,

      //form error alert
      showInputError: false,
      validationMsg: "",

      //watch data form validation
      valid: false,
      bluredEstimatedFinishDate: false,
      bluredDispatchDate: false,
      bluredDeliveryDate: false,
      bluredDeliveryCarrier: false,
      bluredTrackingId: false,

      //data
      userId: "",
      watchId: "",
      modelId: "",
      serviceId: "",
      appointmentId: "",
      appointment: {
        date: new Date()
      },

      user: {
        id: "",
        firstName: "",
        lastName: ""
      },
      watch: {
        modelId: "",
        userId: "",
        status: 0,
        serial: "",
        purchasedOn: new Date().toISOString().substring(0, 10),
        purchasedAt: "",
        expiry: new Date(),
        warrantyId: ""
      },
      model: {
        id: "",
        name: ""
      },
      service: {
        name: ""
      },
      editData: {
        customerDsipatchDate: new Date(),
        customerTrackingId: "",
        receivedOn: new Date(),
        estimatedFinishDate: new Date().toISOString().substring(0, 10),
        finishedOn: new Date(),
        dispatchDate: new Date().toISOString().substring(0, 10),
        deliveryDate: new Date().toISOString().substring(0, 10),
        deliveryCarrier: "",
        deliveryTrackingId: "",
        deliveredOn: new Date(),
        declineReason: ""
      },
      uploadData: {}
    };
  },
  methods: {
    //LOAD watch
    getWatch: function () {
      db.collection(C.COLLECTION.WATCHES)
        .doc(this.watchId)
        .get()
        .then(doc => {
          const docdata = doc.data();
          docdata.id = doc.id;
          this.watch = docdata;

          //load watch model
          this.modelId = doc.data().modelId;
          this.getWatchModel();
        })
        .catch(error => {
          console.log("Error getting WATCH document: ", error);
        });
    },
    //LOAD user
    getUser: function () {
      var docRef = db.collection(C.COLLECTION.USERS).doc(this.userId);
      docRef
        .get()
        .then(doc => {
          const docdata = doc.data();
          docdata.id = doc.id;
          this.user = docdata;
        })
        .catch(function (error) {
          console.log("Error getting USER document:", error);
        });
    },
    //LOAD service
    getService: function () {
      db.collection(C.COLLECTION.SERVICE_CATALOG)
        .doc(this.serviceId)
        .get()
        .then(doc => {
          const docdata = doc.data();
          docdata.id = doc.id;
          this.service = docdata;
        })
        .catch(error => {
          console.log("Error getting WATCH document: ", error);
        });
    },
    //LOAD watch model
    getWatchModel: function () {
      db.collection(C.COLLECTION.WATCH_CATALOG)
        .doc(this.modelId)
        .get()
        .then(doc => {
          const docdata = doc.data();
          docdata.id = doc.id;
          this.model = docdata;
        })
        .catch(error => {
          console.log("Error getting WATCH MODEL document: ", error);
        });
    },
    //next step
    next() {
      if (this.appointment.status == C.STATUS.APPOINTMENT_STATUS.UNCONFIRMED) {
        return;
      } else if (this.appointment.status == C.STATUS.APPOINTMENT_STATUS.CONFIRMED) {
        this.updateAppointmentStatus(C.STATUS.APPOINTMENT_STATUS.WATCH_RECEIVED);
      } else if (this.appointment.status == C.STATUS.APPOINTMENT_STATUS.WATCH_RECEIVED) {
        //set finish date
        this.updateStatusInProgress();
        //this.updateAppointmentStatus(C.STATUS.APPOINTMENT_STATUS.IN_PROGRESS);
      } else if (this.appointment.status == C.STATUS.APPOINTMENT_STATUS.IN_PROGRESS) {
        this.updateAppointmentStatus(C.STATUS.APPOINTMENT_STATUS.WORK_COMPLETED);
      } else if (this.appointment.status == C.STATUS.APPOINTMENT_STATUS.WORK_COMPLETED) {
        this.updateStatusInDelivery();
        //this.updateAppointmentStatus(C.STATUS.APPOINTMENT_STATUS.IN_DELIVERY);
      } else if (this.appointment.status == C.STATUS.APPOINTMENT_STATUS.IN_DELIVERY) {
        this.updateAppointmentStatus(C.STATUS.APPOINTMENT_STATUS.COMPLETED);
      } else if (this.appointment.status == C.STATUS.APPOINTMENT_STATUS.COMPLETED) {
        return;
      } else if (this.appointment.status == C.STATUS.APPOINTMENT_STATUS.DECLINED) {
        return;
      }
    },
    //previous step
    previous() {
      if (this.appointment.status == C.STATUS.APPOINTMENT_STATUS.UNCONFIRMED) {
        return;
      } else if (this.appointment.status == C.STATUS.APPOINTMENT_STATUS.CONFIRMED) {
        this.updateAppointmentStatus(C.STATUS.APPOINTMENT_STATUS.UNCONFIRMED);
      } else if (this.appointment.status == C.STATUS.APPOINTMENT_STATUS.WATCH_RECEIVED) {
        this.updateAppointmentStatus(C.STATUS.APPOINTMENT_STATUS.CONFIRMED);
      } else if (this.appointment.status == C.STATUS.APPOINTMENT_STATUS.IN_PROGRESS) {
        this.updateAppointmentStatus(C.STATUS.APPOINTMENT_STATUS.WATCH_RECEIVED);
      } else if (this.appointment.status == C.STATUS.APPOINTMENT_STATUS.WORK_COMPLETED) {
        this.updateAppointmentStatus(C.STATUS.APPOINTMENT_STATUS.IN_PROGRESS);
      } else if (this.appointment.status == C.STATUS.APPOINTMENT_STATUS.IN_DELIVERY) {
        this.updateAppointmentStatus(C.STATUS.APPOINTMENT_STATUS.WORK_COMPLETED);
      } else if (this.appointment.status == C.STATUS.APPOINTMENT_STATUS.COMPLETED) {
        this.updateAppointmentStatus(C.STATUS.APPOINTMENT_STATUS.IN_DELIVERY);
      } else if (this.appointment.status == C.STATUS.APPOINTMENT_STATUS.DECLINED) {
        this.updateAppointmentStatus(C.STATUS.APPOINTMENT_STATUS.UNCONFIRMED);
      }
    },
    getNextBtnText() {
      var string = "Next";
      return string;
    },
    // UPDATE
    updateAppointment() {
      this.isSaving = true;
      console.log("UPDATING");
      console.log(this.uploadData);
      db.collection(C.COLLECTION.APPOINTMENTS)
        .doc(this.appointmentId)
        .set(this.uploadData, { merge: true })
        .then(() => {
          this.isSaving = false;
          toast.success("Appointment updated.");
        })
        .catch(error => {
          this.isSaving = false;
          toast.error("Couldn't update appointment. " + error.message);
        });
    },
    // UPDATE appointment status
    updateAppointmentStatus(status) {
      this.isSaving = true;
      db.collection(C.COLLECTION.APPOINTMENTS)
        .doc(this.appointmentId)
        .set(
          {
            modifiedOn: serverTimestamp,
            status: status
          },
          { merge: true }
        )
        .then(() => {
          this.isSaving = false;
          toast.success("Appointment status changed.");
        })
        .catch(error => {
          this.isSaving = false;
          toast.error("Couldn't change appointment status. " + error.message);
        });
    },
    updateStatusDeclined() {
      this.uploadData = {
        status: C.STATUS.APPOINTMENT_STATUS.DECLINED,
        declineReason: this.editData.declineReason ? this.editData.declineReason : "",
        modifiedOn: serverTimestamp
      };
      this.updateAppointment();
      this.$refs["decline-modal"].hide();
    },
    updateStatusInProgress() {
      var date = "";
      if (this.editData.estimatedFinishDate && this.editData.estimatedFinishDate != "Invalid date") {
        date = new Date(this.editData.estimatedFinishDate + "T00:00:00.000");
      }

      this.uploadData = {
        status: C.STATUS.APPOINTMENT_STATUS.IN_PROGRESS,
        estimatedFinishDate: date,
        modifiedOn: serverTimestamp
      };
      this.updateAppointment();
    },
    updateStatusInDelivery() {
      var dispatchDate = "";
      var deliveryDate = "";

      if (this.editData.dispatchDate && this.editData.dispatchDate != "Invalid date") {
        dispatchDate = new Date(this.editData.dispatchDate + "T00:00:00.000");
      }
      if (this.editData.deliveryDate && this.editData.deliveryDate != "Invalid date") {
        deliveryDate = new Date(this.editData.deliveryDate + "T00:00:00.000");
      }

      this.uploadData = {
        status: C.STATUS.APPOINTMENT_STATUS.IN_DELIVERY,
        dispatchDate: dispatchDate,
        deliveryDate: deliveryDate,
        deliveryCarrier: this.editData.deliveryCarrier ? this.editData.deliveryCarrier : "",
        deliveryTrackingId: this.editData.deliveryTrackingId ? this.editData.deliveryTrackingId : "",
        modifiedOn: serverTimestamp
      };
      this.updateAppointment();
    },
    openDeclineDialog() {
      this.uploadData = {};
      this.editData.declineReason = "";
      this.$refs["decline-modal"].show();
    },
    validate() {
      //   this.bluredSerialNumber = true;
      //   if (!this.validSerial(this.watch.serial)) {
      //     this.valid = false;
      //     return false;
      //   }

      this.valid = true;
      return true;
    },
    validInput(input) {
      return input && input.trim().length > 0;
    },
    validSerial(input) {
      return input.trim().length > 9;
    },
    validDate(input) {
      return input != null && input != "";
    },
  },
  mounted() {
    //init

    //get id from params
    if (this.$route.params.id != null && this.$route.params.id != "") {
      this.appointmentId = this.$route.params.id;
    }

    //load appointment document
    var docRef = db.collection(C.COLLECTION.APPOINTMENTS).doc(this.appointmentId);
    docRef.onSnapshot(doc => {
      if (doc.exists) {
        this.appointment = doc.data();
        //step 3
        this.editData.estimatedFinishDate = moment(
          C.getDateFromTimestamp(doc.data().estimatedFinishDate)
        ).format("YYYY-MM-DD");
        //step 5
        this.editData.dispatchDate = moment(C.getDateFromTimestamp(doc.data().dispatchDate)).format(
          "YYYY-MM-DD"
        );
        this.editData.deliveryDate = moment(C.getDateFromTimestamp(doc.data().deliveryDate)).format(
          "YYYY-MM-DD"
        );
        this.editData.deliveryCarrier = doc.data().deliveryCarrier;
        this.editData.deliveryTrackingId = doc.data().deliveryTrackingId;
        //decline
        //this.editData.declineReason = doc.data().declineReason;

        //load user
        this.userId = doc.data().userId;
        this.getUser();

        //load watch
        this.watchId = doc.data().watchId;
        this.getWatch();

        //load service
        this.serviceId = doc.data().serviceId;
        this.getService();
      } else {
        // doc.data() will be undefined in this case
        console.log("Appointment document not found. No such Id!");
        this.showError = true;
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.grid-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  align-items: flex-start;
}

.appointment-step {}

.appointment-step-content {
  // min-height: 30vh;
  margin-top: 50px;
  margin-bottom: 50px;
}

@media (max-width: 997px) {
  .grid-4 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    align-items: flex-start;
  }
}
</style>
